import { Select } from '@sumup-oss/circuit-ui';
import type { ChangeEvent, FC } from 'react';

import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';

import {
  CountrySelect,
  StyledDiv,
  StyledFlagIcon,
} from './LocaleDropdown.styles';

export interface CountryLanguage {
  code: string;
  name: string;
}

export interface CountryWithLocale {
  /** Country ISO code */
  code: string;

  /** Name of the country */
  name: string;

  /** ISO of default country language */
  defaultLanguageCode: string;

  /** List of available languages for country */
  availableLanguages: CountryLanguage[];
}

export interface Props {
  countries: CountryWithLocale[];
  currentLocale: string;
  countryLabel?: string;
  languageLabel?: string;
  onLocaleChange: (localeCode: string) => unknown;
  onCountryChange: (countryCode: string) => unknown;
}

export const LocaleDropdown: FC<Props> = ({
  countries,
  onCountryChange,
  onLocaleChange,
  currentLocale,
  countryLabel = 'Select your country',
  languageLabel = 'Select your language',
}) => {
  const currentCountry = countries.find(
    (country) => country.code === getCountryCodeFromISOLocale(currentLocale),
  );

  if (!currentCountry) {
    return null;
  }

  const handleSelectCountry = (e: ChangeEvent<HTMLSelectElement>): void => {
    onCountryChange(e.currentTarget.value);
  };

  const handleSelectLanguage = (e: ChangeEvent<HTMLSelectElement>): void => {
    onLocaleChange(e.currentTarget.value);
  };

  const countryOptions = countries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  const languageOptions = currentCountry?.availableLanguages.map((locale) => ({
    label: locale.name,
    value: `${locale.code}-${currentCountry.code}`,
  }));

  const shouldShowLanguageSelection =
    currentCountry?.availableLanguages.length > 1;

  return (
    <StyledDiv>
      <CountrySelect
        isFullWidth={!shouldShowLanguageSelection}
        label={countryLabel}
        value={currentCountry.code}
        onChange={handleSelectCountry}
        options={countryOptions}
        data-testid="country-select"
        hideLabel
        renderPrefix={() => (
          <StyledFlagIcon
            countryCode={currentCountry.code}
            alt={currentCountry.name}
          />
        )}
      />

      {shouldShowLanguageSelection && (
        <Select
          label={languageLabel}
          value={currentLocale}
          onChange={handleSelectLanguage}
          options={languageOptions}
          hideLabel
          data-testid="language-select"
        />
      )}
    </StyledDiv>
  );
};
