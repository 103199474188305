import type { FC } from 'react';

import { HaveAReaderBar } from 'productSelection/components/HaveAReaderBar';
import type { IFooterFields } from 'shared/infra/contentful/contentful';
import { useTypedSelector } from 'shared/store';
import { getChannel } from 'shared/utils/channel-link';
import {
  showCookieConsentPreferences,
  useCookieConsent,
} from 'utils/scripts/cookieConsent/cookieConsent';

import {
  LocaleDropdown,
  type Props as LocaleDropdownProps,
} from '../LocaleDropdown/LocaleDropdown';

import {
  CookiePreferenceContainer,
  StyledAnchor,
  StyledCol,
  StyledFooter,
  StyledGrid,
} from './Footer.styles';

export interface Props {
  localeDropdownProps?: LocaleDropdownProps;
  footerContent: IFooterFields;
  isProductSelectionPage?: boolean;
  isProductDetailsPage?: boolean;
  isFromProductCTA?: boolean;
  isAddressPage?: boolean;
  isReducedPdpExpEnabled?: boolean;
  message?: string;
  actionTitle?: string;
  actionUrl?: string;
  hasPDPDetails?: boolean;
  hideCart?: boolean;
}

export const Footer: FC<Props> = ({
  footerContent,
  localeDropdownProps,
  isProductSelectionPage = false,
  isProductDetailsPage = false,
  isReducedPdpExpEnabled = false,
  message,
  actionTitle,
  actionUrl,
  hasPDPDetails = true,
  hideCart = false,
}) => {
  const channel = getChannel();
  const { products: orderProducts, orderDetails } = useTypedSelector(
    (state) => state.order,
  );
  const { isEnabled: isCookieConsentEnabled } = useCookieConsent();
  const hasCartBar = isProductDetailsPage && hasPDPDetails;
  const isCartVisible = (orderProducts.length > 0 || hasCartBar) && !hideCart;
  const isDashboardShop = channel === 'dashboard';
  const showHaveAReader = !isDashboardShop && isProductSelectionPage;
  const showFooter = isProductSelectionPage || isCookieConsentEnabled;

  return (
    showFooter && (
      <StyledFooter
        isCartVisible={isCartVisible}
        isReducedPdpExpEnabled={isReducedPdpExpEnabled}
        isProductDetailsPage={isProductDetailsPage}
        isProductSelectionPage={isProductSelectionPage}
      >
        <StyledGrid>
          <StyledCol>
            {showHaveAReader && (
              <HaveAReaderBar
                orderId={orderDetails.id}
                message={message}
                actionTitle={actionTitle}
                actionUrl={actionUrl}
              />
            )}

            {isCookieConsentEnabled && (
              <CookiePreferenceContainer
                isProductSelectionPage={isProductSelectionPage}
              >
                <StyledAnchor onClick={showCookieConsentPreferences}>
                  {footerContent.cookiePolicy}
                </StyledAnchor>
              </CookiePreferenceContainer>
            )}
          </StyledCol>

          {isProductSelectionPage && (
            <StyledCol>
              <LocaleDropdown {...localeDropdownProps} />
            </StyledCol>
          )}
        </StyledGrid>
      </StyledFooter>
    )
  );
};
